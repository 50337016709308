import React from 'react';
import tw, { styled } from 'twin.macro';
import PropTypes from 'prop-types';

import { motion, AnimateSharedLayout } from '@ubisend/framer-motion';
import {
  Panel,
  Flex,
  ModalClose,
  TabButton,
  Divider
} from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { LanguagesConsumer } from '@ubisend/pulse-hooks';

import Blocks from './Blocks';
import Settings from './Settings';
import IconWrapper from '../IconWrapper';
import { useBank, useCanvas } from '../../hooks/index';
import { TopBar } from '../../Components/index';

const Container = styled(motion.div)`
  ${tw`flex items-start absolute z-20`}
  top: 1rem;
  right: 1rem;
  pointer-events: none;
`;

const CloseButton = styled(Panel)`
  ${tw`mr-4 p-1 flex`}
  pointer-events: auto;
`;

const BankBodyPanel = styled(Panel)`
  ${tw`w-96 h-full bg-white shadow overflow-y-auto flex flex-col flex-shrink-0 p-0 overflow-x-hidden`}
  pointer-events: auto;
`;

const TABS = {
  BLOCKS: 'BLOCKS',
  SETTINGS: 'SETTINGS',
  DEMO: 'DEMO'
};

const styles = {
  [TABS.BLOCKS]: {},
  [TABS.SETTINGS]: {},
  [TABS.DEMO]: { height: '500px' }
};

const Bank = ({ demo }) => {
  const { show, tab, dispatch } = useBank();
  const { setPanningEnabled } = useCanvas();

  return (
    <Container
      initial={{ x: '25rem' }}
      animate={{ x: show ? '0rem' : '25rem' }}
      transition={{
        type: 'spring',
        stiffness: 300,
        damping: 30
      }}>
      <CloseButton>
        {show ? (
          <ModalClose
            aria-label="Hide bank"
            onClick={() => dispatch({ type: 'HIDE_BANK' })}
          />
        ) : (
          <IconWrapper
            aria-label="Show bank"
            onClick={() => dispatch({ type: 'SHOW_BANK' })}>
            <Icon
              size="1.5rem"
              width="1.5rem"
              height="1.5rem"
              type="viewGridAdd"
              outline
            />
          </IconWrapper>
        )}
      </CloseButton>
      <BankBodyPanel>
        <TopBar />
        <Divider fullWidth mbNone mtNone />
        <Flex xSpace pad>
          <AnimateSharedLayout>
            <TabButton
              active={tab === TABS.BLOCKS}
              onClick={() => dispatch({ type: 'SHOW_BLOCKS' })}>
              Blocks
            </TabButton>
            <LanguagesConsumer>
              <TabButton
                active={tab === TABS.SETTINGS}
                onClick={() => dispatch({ type: 'SHOW_SETTINGS' })}>
                Settings
              </TabButton>
            </LanguagesConsumer>
            <TabButton
              active={tab === TABS.DEMO}
              onClick={() => dispatch({ type: 'SHOW_DEMO' })}>
              Demo
            </TabButton>
          </AnimateSharedLayout>
        </Flex>
        <Divider fullWidth mbNone mtNone />
        <Flex
          col
          style={styles[tab]}
          onMouseEnter={() => setPanningEnabled(false)}
          onMouseLeave={() => setPanningEnabled(true)}>
          {tab === TABS.BLOCKS && <Blocks />}
          {tab === TABS.SETTINGS && <Settings />}
          {tab === TABS.DEMO && demo}
        </Flex>
      </BankBodyPanel>
    </Container>
  );
};

Bank.propTypes = {
  demo: PropTypes.object.isRequired
};

export default Bank;
