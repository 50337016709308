import React from 'react';

import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import WhatsAppLink from './Pages/index';

const routes = [
  {
    name: 'WhatsApp',
    path: '/whatsapp-link',
    component: () => <WhatsAppLink />,
    canAccess: [
      hasActiveFeatureGate('channel - whatsapp'),
      hasPermission('view whatsapp settings')
    ],
    actions: [
      {
        title: 'Manage your WhatsApp channel',
        description: 'Connect, disconnect, or edit your whatsApp integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
