import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import tw, { styled } from 'twin.macro';
import { useDrag } from 'react-dnd';
import chroma from 'chroma-js';

import { motion } from '@ubisend/framer-motion';
import { Grid, Flex, Span } from '@ubisend/pulse-components';
import Icon from '@ubisend/pulse-icons';
import { useAuth, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import blockTypes from '../Blocks/Types/index';
import nodeTypes from '../Nodes/Types/index';
import { useCanvas } from '../../hooks/index';

const categories = [
  {
    name: 'Steps',
    blocks: [nodeTypes.messageStep, nodeTypes.automatedStep]
  },
  {
    name: 'Step Actions',
    blocks: [
      blockTypes.integration,
      blockTypes.email,
      blockTypes.action,
      nodeTypes.trigger,
      nodeTypes.validation
    ]
  },
  {
    name: 'Transition Actions',
    blocks: [blockTypes.variable, blockTypes.metric]
  }
];

const SectionHeader = styled(motion.h2)`
  && {
    ${tw`block text-xs uppercase tracking-wide font-semibold font-poppins text-black`}
  }
`;

const Item = styled(motion.div)`
  ${tw`px-2 py-2 flex items-center rounded`}
  cursor: grab;
  color: ${props =>
    ['messageStep', 'automatedStep'].includes(props.blockType)
      ? '#000000'
      : '#3251A4'};
  background: ${props =>
    ['messageStep', 'automatedStep'].includes(props.blockType)
      ? '#F9F9F9'
      : '#F5F8FF'};
  & svg {
    ${tw`w-6 h-6 mr-3 flex`}
  }
  & span {
    ${tw`font-poppins text-sm font-semibold`}
  }
  &:hover {
    background: ${props =>
      ['messageStep', 'automatedStep'].includes(props.blockType)
        ? chroma('#F9F9F9').darken(0.2)
        : '#E5ECFB'};
    box-shadow: ${props =>
      ['messageStep', 'automatedStep'].includes(props.blockType)
        ? '0px 4px 8px -4px rgba(0, 0, 0, 0.4)'
        : '0px 4px 8px -4px rgba(50, 81, 164, 0.6)'};
  }
  &:focus:not(:focus-visible) {
    box-shadow: inset 0px 0px 0px 2px
      ${props =>
        ['messageStep', 'automatedStep'].includes(props.blockType)
          ? '#908A9E'
          : '#5E87F7'};
    outline: none;
  }
  &:focus-visible {
    outline: 2px solid
      ${props =>
        ['messageStep', 'automatedStep'].includes(props.blockType)
          ? '#908A9E'
          : '#5E87F7'};
  }
`;

const DraggableItem = ({ block, ...props }) => {
  const ref = useRef(null);
  const { setHoveredBlock } = useCanvas();

  const [, drag] = useDrag({
    type: 'BLOCK',
    item: () => block
  });

  drag(ref);

  const blockType = categories.find(category => {
    return category.blocks.find(({ id }) => {
      return block.id === id;
    });
  });

  return (
    <Item
      type="button"
      tabIndex="0"
      blockType={block.id}
      ref={ref}
      onMouseOver={() => setHoveredBlock(blockType)}
      onClick={() => setHoveredBlock(null)}
      onMouseLeave={() => setHoveredBlock(null)}
      whileHover={{
        scale: 1.025,
        y: -3,
        transition: {
          type: 'spring',
          stiffness: 800,
          damping: 70,
          restDelta: 0.001
        }
      }}
      {...props}
    />
  );
};

DraggableItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  block: PropTypes.object.isRequired
};

const Blocks = () => {
  const auth = useAuth();
  const { hasPermission } = useAuth();

  const filteredCategories = categories.map(category => ({
    ...category,
    blocks: category.blocks.filter(category => {
      if (category.name === 'Email') {
        return hasActiveFeatureGate('emails')(auth);
      } else if (category.name === 'Integration') {
        return hasActiveFeatureGate('integrations')(auth);
      }

      if (category.permission) {
        return hasPermission(`view ${category.permission}`);
      }

      return true;
    })
  }));

  return (
    <Flex col ySpace pad>
      {filteredCategories.map((category, key) => (
        <Flex col key={key}>
          <SectionHeader>{category.name}</SectionHeader>
          {category.blocks.length === 0 && (
            <Span tinyText light>
              No available blocks
            </Span>
          )}
          {category.blocks.length > 0 && (
            <Grid columns={2}>
              {category.blocks.map(block => (
                <DraggableItem
                  block={block}
                  colour={block.colour}
                  key={block.id}>
                  <Icon type={block.icon} outline />
                  <span>{block.name}</span>
                </DraggableItem>
              ))}
            </Grid>
          )}
        </Flex>
      ))}
    </Flex>
  );
};

export default Blocks;
export { categories };
