const hasActiveFeatureGate = key => auth => {
  if (!auth?.client) {
    return false;
  }

  return Boolean(
    auth.client.feature_gates?.find(
      feature =>
        feature.name === key && (feature.state === true || feature.state === 1)
    )
  );
};

export default hasActiveFeatureGate;
