import React from 'react';

import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import RcsLink from './Pages/index';

const routes = [
  {
    name: 'RCS',
    path: '/rcs-link',
    component: () => <RcsLink />,
    canAccess: [
      hasActiveFeatureGate('channel - rcs'),
      hasPermission('view rcs settings')
    ],
    actions: [
      {
        title: 'Manage your RCS channel',
        description: 'Connect, disconnect, or edit your RCS integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
