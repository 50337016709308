import FuzzyInput from './FuzzyInput';

const fuzzy = {
  id: 'fuzzy',
  name: 'Fuzzy',
  Input: FuzzyInput,
  setInitialContent: content => {
    const items = content.items || [];

    return {
      items: items.map((item, index) => {
        return { id: index + 1, ...item };
      }),
      placeholder: content.placeholder || 'Choose an option...',
      multi: content.multi !== undefined ? content.multi : false
    };
  },
  formatContent: content => {
    return {
      items: content.items.map(({ id, ...item }) => item),
      placeholder: content.placeholder,
      multi: content.multi
    };
  },
  valid: content => {
    return Boolean(
      content.placeholder &&
        content.items.length > 0 &&
        content.items.every(item => item.label && item.value)
    );
  },
  snippet: `{
    "type": "fuzzy",
    "content": {
      "placeholder": "Choose an option...",
      "multi": false,
      "items": [
        {"label": "", "value": ""}
      ]
    }
}`
};

export default fuzzy;
