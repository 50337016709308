import { useReducer } from 'react';

const TYPES = {
  // Core
  UPDATE_NAME: 'UPDATE_NAME',
  UPDATE_EMAIL: 'UPDATE_EMAIL',
  UPDATE_LANGUAGE_ID: 'UPDATE_LANGUAGE_ID',

  // Features
  ADD_FEATURE: 'ADD_FEATURE',
  REMOVE_FEATURE: 'REMOVE_FEATURE',

  // Optional
  UPDATE_PHONE: 'UPDATE_PHONE',
  UPDATE_ADDRESS_1: 'UPDATE_ADDRESS_1',
  UPDATE_ADDRESS_2: 'UPDATE_ADDRESS_2',
  UPDATE_CITY: 'UPDATE_CITY',
  UPDATE_REGION: 'UPDATE_REGION',
  UPDATE_POSTCODE: 'UPDATE_POSTCODE',
  UPDATE_COUNTRY: 'UPDATE_COUNTRY'
};

const reducer = (state, { type, ...params }) => {
  switch (type) {
    // Core
    case TYPES.UPDATE_NAME:
      return { ...state, name: params.name };
    case TYPES.UPDATE_EMAIL:
      return { ...state, email: params.email };
    case TYPES.UPDATE_LANGUAGE_ID:
      return { ...state, language_id: params.language_id };

    // Features
    case TYPES.ADD_FEATURE:
      return { ...state, features: state.features.concat(params.feature) };
    case TYPES.REMOVE_FEATURE:
      return {
        ...state,
        features: state.features.filter(feature => feature !== params.feature)
      };

    // Optional
    case TYPES.UPDATE_PHONE:
      return { ...state, phone: params.phone };
    case TYPES.UPDATE_ADDRESS_1:
      return { ...state, address_1: params.address_1 };
    case TYPES.UPDATE_ADDRESS_2:
      return { ...state, address_2: params.address_2 };
    case TYPES.UPDATE_CITY:
      return { ...state, city: params.city };
    case TYPES.UPDATE_REGION:
      return { ...state, region: params.region };
    case TYPES.UPDATE_POSTCODE:
      return { ...state, postcode: params.postcode };
    case TYPES.UPDATE_COUNTRY:
      return { ...state, country: params.country };

    default:
      throw new Error(`No event defined in useClientReducer for ${type}`);
  }
};

const defaultFeatureGates = [
  { name: 'faq regression testing', state: true },
  { name: 'broadcast', state: true },
  { name: 'knowledge bases', state: true },
  { name: 'builder', state: true },
  { name: 'languages', state: true },
  { name: 'owner area', state: true },
  { name: 'whitelabel footer', state: true },
  { name: 'payments', state: true },
  { name: 'small talk', state: true },
  { name: 'emails', state: true },
  { name: 'generative ai', state: false },
  { name: 'live chats', state: true },
  { name: 'authorisation', state: true },
  { name: 'templating', state: true },
  { name: 'integrations', state: true },
  { name: 's3 storage', state: true },
  { name: 'channel - facebook workplace', state: true },
  { name: 'channel - facebook messenger', state: true },
  { name: 'channel - converse', state: true },
  { name: 'channel - instagram', state: true },
  { name: 'channel - microsoft teams', state: true },
  { name: 'channel - sms', state: true },
  { name: 'channel - telegram', state: true },
  { name: 'channel - whatsapp', state: true },
  { name: 'channel - embedded', state: true },
  { name: 'channel - full page', state: true },
  { name: 'channel - alexa', state: true },
  { name: 'channel - voip', state: true },
  { name: 'channel - rcs', state: true },
  { name: 'auth - sso', state: true },
  { name: 'semantic search', state: true },
  { name: 'feedback', state: true }
];

const defaultClient = {
  name: '',
  email: '',
  language_id: null,
  features: [],
  feature_gates: defaultFeatureGates,
  phone: '',
  address_1: '',
  address_2: '',
  city: '',
  region: '',
  postcode: '',
  country: null
};

const setInitialClient = client => {
  return {
    name: client.name || '',
    email: client.email || '',
    language_id: client.default_language ? client.default_language.id : null,
    features: client.features.map(feature => feature.key),
    phone: client.phone || '',
    address_1: client.address_1 || '',
    address_2: client.address_2 || '',
    city: client.city || '',
    region: client.region || '',
    postcode: client.postcode || '',
    country: client.country || '',
    feature_gates: client.feature_gates || []
  };
};

const useClient = (initialClient = defaultClient) => {
  const [client, dispatch] = useReducer(
    reducer,
    setInitialClient(initialClient)
  );

  const valid = ['name', 'email', 'language_id'].every(key => {
    return Boolean(client[key]);
  });

  const hasFeature = key => {
    return client.features.includes(key);
  };

  const hasActiveFeatureGate = key => {
    return Boolean(
      client.feature_gates?.find(
        feature =>
          feature.name === key &&
          (feature.state === true || feature.state === 1)
      )
    );
  };

  return {
    client,
    dispatch,
    valid,
    form: client,
    TYPES,
    hasFeature,
    hasActiveFeatureGate
  };
};

export default useClient;
