import { hasFeature, hasPermission } from '@ubisend/pulse-auth';

import {
  EditSmallTalk,
  ManageSmallTalk,
  SmallTalkSettings
} from './Pages/index';

const routes = [
  {
    path: '/smalltalk',
    component: ManageSmallTalk,
    name: 'Small Talk',
    canAccess: [hasFeature('small talk'), hasPermission('view small talk')]
  },
  {
    path: '/smalltalk/settings',
    component: SmallTalkSettings,
    name: 'Small Talk',
    canAccess: [
      hasFeature('small talk'),
      hasPermission('view small talk settings')
    ]
  },
  {
    path: '/smalltalk/:smallTalkId/edit',
    component: EditSmallTalk,
    name: 'Edit Small Talk',
    canAccess: [hasFeature('small talk'), hasPermission('edit small talk')]
  }
];

export default routes;
