import PropTypes from 'prop-types';

import { useAuth } from '../hooks/index';
import { hasActiveFeatureGate } from '../callbacks/index';

const GlobalFeatureGateFilter = ({ children, for: role }) => {
  const auth = useAuth();

  if (hasActiveFeatureGate(role)(auth)) {
    return children;
  }

  return null;
};

GlobalFeatureGateFilter.propTypes = {
  for: PropTypes.string.isRequired
};

export default GlobalFeatureGateFilter;
