import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { GenerativeAi } from './Pages/index';

const routes = [
  {
    path: '/generative-ai',
    component: GenerativeAi,
    name: 'Generative AI',
    canAccess: [
      hasActiveFeatureGate('generative ai'),
      hasPermission('view generative ai')
    ]
  }
];

export default routes;
