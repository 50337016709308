import {
  canWhiteLabel,
  hasGlobalRole,
  hasPermission,
  hasActiveFeatureGate
} from '@ubisend/pulse-auth';

import {
  AccountsPage,
  Languages,
  Users,
  Licenses,
  WhiteLabel
} from './Pages/index';

const routes = [
  {
    path: '/owner/accounts',
    component: AccountsPage,
    name: 'Accounts',
    canAccess: [hasActiveFeatureGate('owner area'), hasGlobalRole('owner')],
    disableBreadCrumbs: true
  },
  {
    path: '/owner/users',
    component: Users,
    name: 'Users',
    canAccess: [hasActiveFeatureGate('owner area'), hasGlobalRole('owner')],
    disableBreadCrumbs: true
  },
  {
    path: '/owner/licenses',
    component: Licenses,
    name: 'Licenses',
    canAccess: [hasActiveFeatureGate('owner area'), hasGlobalRole('owner')],
    exact: false
  },
  {
    path: '/owner/white-label',
    component: WhiteLabel,
    name: 'White Label',
    canAccess: [hasActiveFeatureGate('owner area'), canWhiteLabel]
  },
  {
    path: '/languages',
    component: Languages,
    name: 'Languages',
    canAccess: [
      hasActiveFeatureGate('languages'),
      hasPermission('deactivate languages')
    ],
    actions: [
      {
        title: 'Manage your languages',
        description: 'Teach your chatbot to understand one or more languages.',
        icon: 'flag'
      }
    ]
  }
];

export default routes;
