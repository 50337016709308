import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { Builder, Conversations } from './Pages/index';

const routes = [
  {
    name: 'Workflows',
    path: '/builder',
    component: Conversations,
    disableBreadCrumbs: true,
    canAccess: [
      hasActiveFeatureGate('builder'),
      hasPermission('view conversations')
    ]
  },
  {
    name: 'Workflow Builder',
    path: '/builder/:id',
    component: Builder,
    disableBreadCrumbs: true,
    canAccess: [
      hasActiveFeatureGate('builder'),
      hasPermission('view conversations')
    ]
  }
];

export default routes;
