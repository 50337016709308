import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { VoipLink } from './Pages/index';

const routes = [
  {
    name: 'Voip',
    path: '/voip-link',
    component: VoipLink,
    canAccess: [
      hasActiveFeatureGate('channel - voip'),
      hasPermission('view voip settings')
    ],
    actions: []
  }
];

export default routes;
