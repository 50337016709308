import {
  hasPermission,
  hasGlobalRole,
  hasActiveFeatureGate
} from './callbacks/index';
import {
  Login,
  Logout,
  EmailPasswordRequestNewPassword,
  EmailPasswordResetPassword,
  Users,
  EmailPasswordAcceptInvite,
  RolesPage,
  LoginMethods,
  LoginMethod,
  OAuthCallback,
  AuthError,
  SwitchAccounts
} from './Pages/index';

const routes = [
  // Public core routes
  {
    name: 'Login',
    path: '/',
    component: Login,
    public: true,
    auth: true
  },
  {
    name: 'Login',
    path: '/index.html',
    component: Login,
    public: true,
    auth: true
  },
  {
    name: 'Login',
    path: '/login',
    component: Login,
    public: true,
    auth: true
  },
  {
    name: 'Logout',
    path: '/logout',
    component: Logout,
    public: true
  },
  {
    name: 'Logout',
    path: '/owner/logout',
    component: Logout,
    public: true,
    disableBreadCrumbs: true
  },
  {
    name: 'Error',
    path: '/auth/error',
    component: AuthError,
    public: true,
    auth: true
  },

  // Email & Password speciifc routes
  {
    name: 'Request New Password',
    path: '/password/request',
    component: EmailPasswordRequestNewPassword,
    public: true,
    disableBreadCrumbs: true,
    auth: true
  },
  {
    name: 'Reset Password',
    path: '/password/reset',
    component: EmailPasswordResetPassword,
    public: true,
    disableBreadCrumbs: true,
    auth: true
  },
  {
    name: 'Accept Invite',
    path: '/invites/accept',
    component: EmailPasswordAcceptInvite,
    public: true,
    disableBreadCrumbs: true,
    auth: true
  },

  // OAuth 2.0 specific routes
  {
    name: 'OAuth Callback',
    path: '/callback',
    component: OAuthCallback,
    public: true,
    auth: true
  },

  // Private Client routes
  {
    name: 'Users',
    path: '/users',
    component: Users,
    canAccess: hasPermission('view users'),
    actions: [
      {
        title: 'Manage users',
        description:
          'Create users, customise permissions, and remove users from your account.',
        icon: 'users'
      },
      {
        title: 'Invite users',
        description: 'Invite new users to your account.',
        icon: 'users'
      }
    ]
  },
  {
    name: 'Roles',
    path: '/roles',
    component: RolesPage,
    canAccess: hasPermission('view roles'),
    actions: [
      {
        title: 'Create new roles',
        description: 'Create role templates with pre-assigned permissions.',
        icon: 'adjustments'
      }
    ]
  },
  {
    name: 'Switch Accounts',
    path: '/accounts/:id/switch',
    component: SwitchAccounts
  },

  // Private Owner routes
  {
    path: '/owner/logins',
    component: LoginMethods,
    name: 'Login Methods',
    canAccess: [hasActiveFeatureGate('owner area'), hasGlobalRole('owner')],
    disableBreadCrumbs: true
  },
  {
    path: '/owner/logins/:name',
    component: LoginMethod,
    name: 'Login Method',
    canAccess: [hasActiveFeatureGate('owner area'), hasGlobalRole('owner')],
    disableBreadCrumbs: true
  }
];

export default routes;
