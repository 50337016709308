import React from 'react';

import { Select } from '@ubisend/pulse-components';
import { useAuth, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { useConditional } from '../hooks/index';
import { group } from '../utils/index';

const SubjectSelect = props => {
  const auth = useAuth();
  const { formattedSubjects, events } = useConditional();

  const featureExclusions = [
    { feature: 'payments', value: 'payment' },
    { feature: 'integrations', value: 'endpoint' }
  ];

  const filteredformattedSubjects = formattedSubjects.filter(
    item =>
      !featureExclusions.some(
        exclusion =>
          !hasActiveFeatureGate(exclusion.feature)(auth) &&
          item.value === exclusion.value
      )
  );

  return (
    <Select
      options={group([...filteredformattedSubjects, ...events])}
      {...props}
    />
  );
};

export default SubjectSelect;
