import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { PaymentDrivers, GlobalPaySettings } from './Pages/index';

const routes = [
  {
    path: '/payments',
    component: PaymentDrivers,
    name: 'Payment Providers',
    canAccess: [
      hasActiveFeatureGate('payments'),
      hasPermission('view payment providers')
    ]
  },
  {
    path: '/global-pay',
    component: GlobalPaySettings,
    name: 'Global Pay',
    canAccess: [
      hasActiveFeatureGate('payments'),
      hasPermission('view global pay settings')
    ]
  }
];

export default routes;
