import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';
import { useAuth, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import actions from './Actions/index';

const format = action => ({ value: action.name, label: action.name });

const ticketActions = [actions.createTicket, actions.createLiveChatTicket].map(
  format
);
const knowledgeBaseActions = [
  actions.exportAllImports,
  actions.exportImport,
  actions.exportSection
].map(format);

const authorisationActions = [
  actions.setCredentials,
  actions.login,
  actions.logout,
  actions.generatePasswordResetToken,
  actions.setPassword
].map(format);

const chatbotUserActions = [
  actions.setLanguage,
  actions.deleteSubscriber,
  actions.setLocation,
  actions.exportTranscript,
  actions.exportSubscriber,
  actions.endCurrentSession
].map(format);

const alexaActions = [
  actions.listenForResponseOnAlexa,
  actions.requestPermissionsOnAlexa
].map(format);

const voipActions = [
  actions.listenForResponseOnVoip,
  actions.transferVoipCall
].map(format);

const feedbackActions = [actions.leaveFeedback].map(format);

const otherActions = [
  actions.triggerPostback,
  actions.triggerPostbackCancel
].map(format);

const groups = [
  {
    label: 'Chatbot user',
    options: chatbotUserActions,
    feature: null,
    featureGate: null
  },
  {
    label: 'Tickets',
    options: ticketActions,
    feature: null,
    featureGate: 'live chats'
  },
  {
    label: 'Knowledge bases',
    options: knowledgeBaseActions,
    feature: null,
    featureGate: 'knowledge bases'
  },
  {
    label: 'Authorisation',
    options: authorisationActions,
    feature: 'authorisation',
    featureGate: 'authorisation'
  },
  {
    label: 'Alexa',
    options: alexaActions,
    feature: null,
    featureGate: 'channel - alexa'
  },
  {
    label: 'Voip',
    options: voipActions,
    feature: null,
    featureGate: 'channel - voip'
  },
  {
    label: 'Feedback',
    options: feedbackActions,
    feature: null,
    featureGate: 'feedback'
  },
  {
    label: 'other',
    options: otherActions,
    feature: null,
    featureGate: null
  }
];

const ActionSelect = ({ value, ...props }) => {
  const auth = useAuth();
  const { hasFeature } = useAuth();

  const filteredGroups = groups.filter(group => {
    if (
      group.featureGate &&
      hasActiveFeatureGate(group.featureGate)(auth) === false
    ) {
      return false;
    }

    if (!group.feature) {
      return true;
    }

    return hasFeature(group.feature);
  });

  return (
    <Select
      options={filteredGroups}
      value={value && format(actions.all.find(action => action.name === value))}
      {...props}
    />
  );
};

ActionSelect.propTypes = {
  value: PropTypes.string
};

export default ActionSelect;
