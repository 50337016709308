import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '@ubisend/pulse-components';
import { useAuth, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import responses from './responses';

const format = response => {
  return {
    label: response.name,
    value: response.id
  };
};

const TypeSelect = ({ value, ...props }) => {
  const auth = useAuth();
  let allResponses = responses;

  const filteredResponses = () => {
    const featureFilters = [
      { feature: 'templating', excludeName: 'Dynamic' },
      { feature: 'payments', excludeName: 'Payment' }
    ];

    featureFilters.forEach(({ feature, excludeName }) => {
      if (!hasActiveFeatureGate(feature)(auth)) {
        allResponses = allResponses.filter(
          response => response.name !== excludeName
        );
      }
    });

    return allResponses;
  };

  return (
    <Select
      options={filteredResponses().map(format)}
      value={
        value
          ? format(filteredResponses().find(response => response.id === value))
          : null
      }
      {...props}
    />
  );
};

TypeSelect.propTypes = {
  value: PropTypes.oneOf(responses.map(({ id }) => id))
};

export default TypeSelect;
