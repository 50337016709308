import React from 'react';

import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import SmsLink from './Pages/index';

const routes = [
  {
    name: 'SMS',
    path: '/sms-link',
    component: () => <SmsLink />,
    canAccess: [
      hasActiveFeatureGate('channel - sms'),
      hasPermission('view sms settings')
    ],
    actions: [
      {
        title: 'Manage your Sms channel',
        description: 'Connect, disconnect, or edit your sms integration.',
        icon: 'lightningBolt'
      }
    ]
  }
];

export default routes;
