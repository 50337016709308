import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { LiveChats, LiveChatDrivers } from './Pages/index';

const routes = [
  {
    path: '/live-chat',
    component: LiveChats,
    name: 'Live Chats',
    exact: false,
    canAccess: [
      hasActiveFeatureGate('live chats'),
      hasPermission('view tickets')
    ],
    actions: [
      {
        title: 'View current live chats',
        description: 'Manage your live chat queue.',
        icon: 'ticket'
      }
    ]
  },
  {
    path: '/live-chat/drivers',
    component: LiveChatDrivers,
    name: 'Live Chat Drivers',
    canAccess: [
      hasActiveFeatureGate('live chats'),
      hasPermission('view live chat providers')
    ]
  }
];

export default routes;
