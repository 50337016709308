import React from 'react';
import PropTypes from 'prop-types';

import { Span } from '@ubisend/pulse-components';

const MessageScope = ({ content }) => {
  if (typeof content.message === 'object' && content.message !== null) {
    return <Span>{JSON.stringify(content.message)}</Span>;
  }

  return <Span>{content.message}</Span>;
};

MessageScope.propTypes = {
  content: PropTypes.shape({
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
      .isRequired
  }).isRequired
};

export default MessageScope;
