import { hasPermission, hasActiveFeatureGate } from '@ubisend/pulse-auth';

import { AlexaLink } from './Pages/index';

const routes = [
  {
    name: 'Alexa',
    path: '/alexa-link',
    component: AlexaLink,
    canAccess: [
      hasActiveFeatureGate('channel - alexa'),
      hasPermission('view alexa settings')
    ],
    actions: []
  }
];

export default routes;
