import React, { useState } from 'react';

import {
  Box,
  Button,
  Modal,
  Flex,
  LoadingContainer,
  Paragraph,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableActions,
  useNotification,
  OrderableTableRow,
  useOrderableTableReducer,
  usePaginationReducer,
  Pagination
} from '@ubisend/pulse-components';
import { PermissionFilter } from '@ubisend/pulse-auth';
import { useQuery, useQueryClient } from '@ubisend/pulse-hooks';

import { deleteSitemap, createSitemap } from '../api/index';
import { SitemapModal, SitemapUrlExplore } from './index';

const Sitemap = () => {
  const { showSuccess, showError } = useNotification();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [sitemapUrl, setSitemapUrl] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSitemap, setSelectedSitemap] = useState(null);

  const options = [
    { value: 'url', label: 'Single URL' },
    { value: 'xml', label: 'XML Sitemap' },
    { value: 'html', label: 'HTML Sitemap' }
  ];
  const order = useOrderableTableReducer({ id: 'sitemaps' });
  const pagination = usePaginationReducer({ id: 'sitemaps' });

  const showModal = () => setIsModalVisible(true);
  const hideModal = () => setIsModalVisible(false);
  const queryClient = useQueryClient();

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [sitemapToDelete, setSitemapToDelete] = useState(null);

  const { data: sitemaps, isLoading, error } = useQuery([
    'generative-ai/sitemap',
    { ...pagination.params, ...order.params }
  ]);

  const handleConfirm = async () => {
    try {
      await createSitemap({
        url: sitemapUrl,
        type: selectedOption.value
      });
      showSuccess(`Successfully created Sitemap.`);
      queryClient.invalidateQueries('generative-ai/sitemap');
      hideModal();
    } catch (error) {
      showError('Failed to create sitemap: ' + error.message);
    }
  };

  const showDeleteModal = sitemap => {
    setSitemapToDelete(sitemap);
    setIsDeleteModalVisible(true);
  };

  const hideDeleteModal = () => {
    setSitemapToDelete(null);
    setIsDeleteModalVisible(false);
  };

  const confirmDelete = async () => {
    try {
      await deleteSitemap(sitemapToDelete.id);
      showSuccess(`Successfully deleted "${sitemapToDelete.url}".`);
      queryClient.invalidateQueries('generative-ai/sitemap');
      hideDeleteModal();
    } catch (error) {
      showError(`Error deleting sitemap: ${error.message}`);
    }
  };

  const closeViewSitemapUrl = () => {
    setSelectedSitemap(null);
  };

  if (isLoading) {
    return <LoadingContainer />;
  }

  if (error) {
    return <div>Error loading sitemaps: {error.message}</div>;
  }

  const meta = sitemaps?.meta ?? {};

  return (
    <Flex col>
      <PermissionFilter can="view generative ai">
        {sitemaps.data?.length === 0 && (
          <Box border="dashed">
            <Flex col middle center>
              <Paragraph mb secondary>
                No web content has been added yet:
              </Paragraph>
              <Button icon="plus" variant="primary" onClick={showModal}>
                Add URLs
              </Button>
              {isModalVisible && (
                <SitemapModal
                  handleClose={hideModal}
                  handleSave={handleConfirm}
                  sitemapUrl={sitemapUrl}
                  setSitemapUrl={setSitemapUrl}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  options={options}
                />
              )}
            </Flex>
          </Box>
        )}
        {sitemaps.data?.length > 0 && (
          <>
            <Flex border xScroll>
              <Table>
                <TableHead>
                  <OrderableTableRow
                    rows={[
                      { label: 'Url', sort: 'url' },
                      { label: 'Type', sort: 'type' },
                      { label: 'Crawled', sort: 'success_count' },
                      null
                    ]}
                    {...order.props}
                  />
                </TableHead>
                <TableBody>
                  {sitemaps.data.map((sitemap, key) => (
                    <TableRow key={key}>
                      <TableCell>{sitemap.url}</TableCell>
                      <TableCell>{sitemap.type}</TableCell>
                      <TableCell>{sitemap.success_count}</TableCell>
                      <TableActions>
                        <PermissionFilter can="edit generative ai">
                          <Button
                            variant="secondary"
                            icon="eye"
                            colour="primary"
                            onClick={() => setSelectedSitemap(sitemap)}>
                            View
                          </Button>
                          <Button
                            variant="secondary"
                            icon="trash"
                            colour="danger"
                            onClick={() => showDeleteModal(sitemap)}>
                            Delete
                          </Button>
                        </PermissionFilter>
                      </TableActions>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Flex>
            {isDeleteModalVisible && (
              <Modal
                header={<div>Confirm Delete</div>}
                body={
                  <div>
                    Are you sure you want to delete{' '}
                    <strong>{sitemapToDelete?.url}</strong>?
                  </div>
                }
                cancelText="Cancel"
                handleCancel={hideDeleteModal}
                confirmText="Yes, Delete"
                handleConfirm={confirmDelete}
              />
            )}
            {meta && <Pagination pagination={meta} {...pagination.props} />}
            <Button mt mlAuto icon="plus" variant="primary" onClick={showModal}>
              Add URLs
            </Button>

            {/* Add URL Modal */}
            {isModalVisible && (
              <SitemapModal
                handleClose={hideModal}
                handleSave={handleConfirm}
                sitemapUrl={sitemapUrl}
                setSitemapUrl={setSitemapUrl}
                selectedOption={selectedOption}
                setSelectedOption={setSelectedOption}
                options={options}
              />
            )}
            <SitemapUrlExplore
              sitemap={selectedSitemap}
              handleHide={closeViewSitemapUrl}
            />
          </>
        )}
      </PermissionFilter>
    </Flex>
  );
};

export default Sitemap;
